  /** paleta de colores **/
  :root {
    --reuse-primary: #221fce;
    --reuse-morado: #7051a0;
    --reuse-azul: #0473c8;
    --reuse-celeste: #ccdaec;
    --reuse-bg: #f8f8f8;
    --reuse-verde: #09aa53;
    --reuse-pink: #f7f7ff;
    --reuse-tbl-th: #d5c9e7;
    --reuse-color-title: #9d9dae;
    --reuse-bg-title: #e9e8eb;
  }
  
  $container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1400px
  );
  
  /* import */
  
  @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap");
  @import '~bootstrap/scss/bootstrap.scss';

  /* framework */
  .bg-celeste{background-color: var(--reuse-celeste);} 
  .bg-azul{background-color: var(--reuse-azul);}
  .bg-morado{background-color: var(--reuse-morado) !important;}
  .color-botton-principal{background-color: var(--reuse-morado);border: none;}
  .color-azul{color:var(--reuse-azul)}
  .border-dashed{border-style:dashed !important}
  .mh-100{min-height: 100%;}
  .tbl-header-views-ot {background-color: var(reuse-color-title)!important; color: #fff; font-family: "Roboto", sans-serif; }
  .cursor-pointer {cursor: pointer;}
  
  /* titulos */
  .title {color: var($black) !important;font-weight: bolder !important;text-transform: uppercase;letter-spacing: 0.0255em};
  
  /* table reuse */
  .tbl-reuse thead th {color: var(--reuse-color-title)!important; background-color: var(--reuse-pink) !important;font-weight: bold; font-size: 12px;}
  .tbl-reuse th, .tbl-reuse td {border:0; padding: 8px;}
  .tbl-reuse tbody tr:nth-child(even) { background: #fafafc; }
  .tbl-reuse thead th .MuiTableSortLabel-icon{color: var(--reuse-primary) !important}
  .tbl-reuse tfoot p{    margin-top: initial;margin-bottom: initial;}
  
  .sticky-ot{ position: sticky; top:76px; z-index: 1020;     box-shadow: 0px 0px 4px 2px #fff;height: 85px;}
  
  
  
  
  .navbar  {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    letter-spacing: 0.075em;
    font-weight: 700;
  }
  
  .navbar .active { 
    color: var(--reuse-color-title);
  }
  
  @media screen and (max-width: 600px) {
    .tableMUI {
        width:100%;
    }
    .TableHead {
        display: none;
    }
    .TableRow:nTableCell-of-type(2n) {
        background-color: inherit;
    }
    .TableRow .TableCell:first-child {
        background: #f0f0f0;
        font-weight:bold;
        font-size:1.3em;
    }
    .TableBody .TableCell {
        display: block;
        text-align:center;
    }
    .TableBody .TableCell:before {
        content: attr(data-th);
        display: block;
        text-align:center;
    }
  }
  
  
  /* general */
  body {
    background-color: var(--reuse-pink);
    margin: 0;
    font-family:  Poppins,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  #root {
    height: 100vh;
    min-height: 600px;
  }
  #responsive-dialog-title.white-dialog-title{
    background:#fff
  }
  .MuiCardContent-root table.table thead tr th.MuiTableCell-head,
  .MuiCardContent-root table.table tr td.MuiTableCell-head{
    background-color: var(--reuse-pink);
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold 
  }
  .MuiDialogContent-root{
    background-color: var(--reuse-pink)
  }
  .MuiDialog-container header{
    background-color: var(--reuse-azul)
  }
  .MuiInputBase-root { background: #f7f7f7}
  [aria-expanded="true"],.Mui-focused{background:#fff}
  .shadow-table-sm {
    box-shadow: 0px 0rem 0.25rem 2px rgb(0 0 0 / 5%) !important;
  }
  .shadow-table-sm table tr:last-child td{
    border:0
  }
  .ot-state-header h6{
    font-size:1.5em !important
  }
  
  .btn {
    border: none;
    text-align: center;
    background-color: rgb(226, 222, 222);
    height: 50px;
    border-radius: 12px;
    color: black;
    font-weight: bold;
    transition-duration: 0.6s;
  }
  
  .btn:hover {
    background-color: blue;
    color: aliceblue;
  }
  
  .btn-upload{position: fixed !important;right: 60px; bottom: 60px;}
  .btn-upload .MuiBadge-badge{z-index: 1051;}
  .dropzone{display: flex;justify-content: center;align-items: center;flex-direction: column; border:dashed 2px $primary;flex: 1 1 auto !important;border-radius: 5px;}
  .dropzone .alert{display: none;}
  .dropzone-accept{box-shadow: inset 0px 0px 20px 1px #00000014;background-color: var(--reuse-pink);}
  .dropzone-reject{border-color: $danger !important;box-shadow: inset 0px 0px 20px 1px #00000014;}
  .dropzone-reject .alert{display: block;}
  .dropzone-reject .instrucciones{display: none;}
  .fileContainer{ position: relative }
  .fileContainer .fileTypeImg,
  .fileContainer .fileActionImg{border-radius: 100%; width: 40px; height: 40px; text-align: center; line-height: 40px}
  .fileContainer .fileTypeImg{ background: #d0e9ff }
  .fileContainer .fileActions{ position: absolute; right: 20px; }
  .fileContainer .fileActions .fileTrash{cursor:pointer;color: #dc3545}
  .fileContainer .fileActions .fileTrash:hover{color: #b61b2a}
  .actionBar button:first-child{margin-right:20px}
  .actionBar button:last-child{margin-left:20px}
  .dropdown .dropdown-toggle{color: #000}
  .dropdown-item{font-weight: 700}
  .dropdown-item:hover, .dropdown-item:focus {color: var(--bs-link-hover-color);background-color: unset}
  .buyMark, .buyMark fieldset legend span, label > .buyMark, #buyMark-label{color:var(--reuse-azul) !important}
  .buyMark fieldset{border-color:var(--reuse-azul) !important}
  .btn-excel .MuiBadge-badge{top: 10px;right: 5px;}
  .story-table tr:nth-child(odd) {
    background-color: #f9f9f9
  }
  .story-table tr:nth-child(even) {
    background-color: #f2f2f2
  }
  .clientAlert .MuiAlert-message{
    overflow: hidden
  }
  .css-1d6wzja-MuiButton-startIcon{
    margin:0 !important
  }
  span.MuiBadge-root span.MuiBadge-badge{
    min-width: 115px;
  }
  .MuiTimelineDot-root svg.MuiSvgIcon-root{
    color: #fff !important
  }

  .MuiTableCell-head button{
    margin:0 auto;
    color: #fff;
  }

  .MuiTable-root th .MuiCheckbox-root{display: none !important;}
  .MuiTable-root th.MuiTableCell-paddingCheckbox{background-color: #ababab !important;}